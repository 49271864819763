import React from 'react'
import Layout from '../components/layout'

class Kontakt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Kontakt | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Kontakt</h2>
              </header>
              <div>
                <p>
                  Persönlich telefonisch erreichen Sie mich am Montag von
                  8:30-10:00 Uhr und am Dienstag bis Freitag zwischen 8:30 und
                  9:00 Uhr.
                </p>
                <p>
                  Sollten Sie auch zu diesen Zeiten ausnahmsweise nur meinen
                  Anrufbeantworter erreichen, sprechen Sie bitte Ihren Namen und
                  Ihre Telefonnummer auf Band, ich rufe so bald als möglich
                  zurück.
                </p>
                <h3>
                  <strong>Angelika Behrenberg</strong>
                </h3>
                <p>Praxisanschrift: Münsterstr. 16, 48291 Telgte</p>
                <p>Fon: 02504/739595 Fax: 02504/739597</p>
                <p>Fax: 02504/739597</p>
                <p>
                  Email:{' '}
                  <a href="mailto:a.behrenberg@psychotherapie-telgte.de">
                    a.behrenberg@psychotherapie-telgte.de
                  </a>
                </p>
                <p>
                  Websiteadmin:{' '}
                  <a href="mailto:webmaster@tfbs.de">webmaster@tfbs.de</a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Kontakt
